// Generated by Framer (6f17e57)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";
import * as sharedStyle from "../css/k3kWCe74H";
import * as sharedStyle1 from "../css/zCLmAjneo";

const enabledGestures = {yXM0ZWtrw: {hover: true}};

const cycleOrder = ["yXM0ZWtrw"];

const variantClassNames = {yXM0ZWtrw: "framer-v-50r6w6"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string; link?: string; newTab?: boolean; smoothScroll?: boolean }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "yXM0ZWtrw", title: Q0n_gFqmK = "Nav Item", link: NTOnIbDOf, newTab: JzvcOuD__ = false, smoothScroll: GY5rHEk7n = false, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "yXM0ZWtrw", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-X2LiH", sharedStyle.className, sharedStyle1.className, classNames)} style={{display: "contents"}}>
<motion.div {...restProps} className={cx("framer-50r6w6", className)} data-framer-name={"Default"} layoutDependency={layoutDependency} layoutId={"yXM0ZWtrw"} ref={ref} style={{...style}} transition={transition} {...addPropertyOverrides({"yXM0ZWtrw-hover": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p className={"framer-styles-preset-1axdrj0"} data-styles-preset={"k3kWCe74H"}><Link href={NTOnIbDOf} openInNewTab={JzvcOuD__} smoothScroll={GY5rHEk7n}><motion.a className={"framer-styles-preset-51jn3d"} data-styles-preset={"zCLmAjneo"}>Nav Item</motion.a></Link></motion.p></React.Fragment>} className={"framer-v2vg4d"} layoutDependency={layoutDependency} layoutId={"KqGKMWRV6"} style={{"--framer-paragraph-spacing": "0px", "--variable-reference-GY5rHEk7n-PmSqLtDl7": GY5rHEk7n, "--variable-reference-JzvcOuD__-PmSqLtDl7": JzvcOuD__}} text={Q0n_gFqmK} transition={transition} verticalAlignment={"top"} withExternalLayout/><motion.div className={"framer-ymgwsm"} data-framer-name={"Line"} layoutDependency={layoutDependency} layoutId={"nrJe6soa3"} style={{backgroundColor: "var(--token-c5c4fdcb-9482-48bb-aedf-353188472aae, rgb(218, 197, 167))"}} transition={transition}/></motion.div>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-X2LiH [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-X2LiH .framer-1u5r8q2 { display: block; }", ".framer-X2LiH .framer-50r6w6 { align-content: center; align-items: center; display: flex; flex-direction: column; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: visible; padding: 0px 0px 0px 0px; position: relative; width: min-content; }", ".framer-X2LiH .framer-v2vg4d { flex: none; height: auto; overflow: visible; position: relative; white-space: pre; width: auto; }", ".framer-X2LiH .framer-ymgwsm { bottom: -4px; flex: none; height: 1px; left: 0px; overflow: visible; position: absolute; width: 0%; z-index: 1; }", ".framer-X2LiH .framer-v-50r6w6 .framer-50r6w6 { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-X2LiH .framer-50r6w6 { gap: 0px; } .framer-X2LiH .framer-50r6w6 > * { margin: 0px; margin-bottom: calc(10px / 2); margin-top: calc(10px / 2); } .framer-X2LiH .framer-50r6w6 > :first-child { margin-top: 0px; } .framer-X2LiH .framer-50r6w6 > :last-child { margin-bottom: 0px; } }", ".framer-X2LiH.framer-v-50r6w6.hover .framer-ymgwsm { width: 100%; }", ...sharedStyle.css, ...sharedStyle1.css]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 15.5
 * @framerIntrinsicWidth 69
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"EsgAefpiA":{"layout":["auto","auto"]}}}
 * @framerVariables {"Q0n_gFqmK":"title","NTOnIbDOf":"link","JzvcOuD__":"newTab","GY5rHEk7n":"smoothScroll"}
 */
const FramerPmSqLtDl7: React.ComponentType<Props> = withCSS(Component, css, "framer-X2LiH") as typeof Component;
export default FramerPmSqLtDl7;

FramerPmSqLtDl7.displayName = "Nav Item";

FramerPmSqLtDl7.defaultProps = {height: 15.5, width: 69};

addPropertyControls(FramerPmSqLtDl7, {Q0n_gFqmK: {defaultValue: "Nav Item", displayTextArea: false, title: "Title", type: ControlType.String}, NTOnIbDOf: {title: "Link", type: ControlType.Link}, JzvcOuD__: {defaultValue: false, title: "New Tab", type: ControlType.Boolean}, GY5rHEk7n: {defaultValue: false, title: "Smooth Scroll", type: ControlType.Boolean}} as any)

addFonts(FramerPmSqLtDl7, [...sharedStyle.fonts, ...sharedStyle1.fonts])